import React, {
  useEffect, useMemo, useState,
} from 'react';
import { capitalize } from 'lodash';
import {
  Alert, Button, Spin,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import UserStatisticsForm from './UserStatisticsForm';

import useAPI from '../../../../api/useAPI';
import { getProfileInfo } from '../../../../redux/profile/selectors';
import { getMonthlyStorageInfo, getMonthlyStorageUUID } from '../../../../redux/storages/selectors';
import antNotification from '../../../../utils/antNotification';
import { dateToFormat4, defaultDate } from '../../../../54origins/dateFormats54origins';

export default function UserReportForLastMonth({ actor, onlySuccessTask }) {
  const myProfileData = useSelector(getProfileInfo);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const monthlyStorageInfo = useSelector(getMonthlyStorageInfo);

  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [monthlyReport, setMonthlyReport] = useState(null);

  const {
    updateMonthlyReport, getOrCreateMonthlyReport,
  } = useAPI();

  const {
    params: {
      statistics: reportStat,
      logs: reportLogs,
      status: reportStatus,
    } = {},
    uuid: reportUUID,
  } = monthlyReport || {};

  const lastMonthDate = dayjs().subtract(1, 'month').format('YYYY-MM');

  const initFunc = async () => {
    setLoading(true);
    const resReport = await getOrCreateMonthlyReport(
      monthlyStorageUUID,
      {
        actor,
        params: {
          date: lastMonthDate,
        },
      },
    );

    setMonthlyReport(resReport);
    setLoading(false);
  };

  const onFinish = async (values = {}, filesForReport = [], uuid = '', files = []) => {
    try {
      if (monthlyStorageUUID && actor) {
        const config = {
          entity_type: 'monthlyReport',
          entity_uuid: uuid || reportUUID,
          actor,
          params: {
            status: 'submitted',
            statistics: {
              ...values,
              created: defaultDate().toString(),
              totalWorks: Math.ceil(values?.totalWorks) ?? 0,
              fullTotalWorks: values?.totalWorks ?? 0,
              totalDayOff: +(values?.sickday ?? 0) + +(values?.vacation ?? 0)
                + +(values?.dayoff ?? 0),
            },
            logs: reportLogs ?? [],
          },
        };

        if (reportStat) {
          let logFiles = [];
          if (reportLogs?.length) {
            logFiles = reportLogs?.reduce?.((acc, log) => {
              if (log?.files?.length) {
                return [...acc, ...(log?.files ?? [])];
              }
              return acc;
            }, []);
          }
          const newLogFiles = files
            ?.filter?.((file) => !logFiles?.includes(file?.uuid))
            .map((el) => el?.uuid);
          config.params.logs.unshift({
            ...(reportStat ?? {}),
            status: reportStatus,
            files: newLogFiles,
          });
        }

        const newFormData = new FormData();

        newFormData.append('data', JSON.stringify(config));

        if (filesForReport.length !== 0) {
          for (let i = 0; i < filesForReport.length; i += 1) {
            newFormData.append('file', filesForReport[i]);
          }
        } else {
          newFormData.append('file', []);
        }

        await updateMonthlyReport(newFormData);
        antNotification.success('Отчет оправлен.');
        initFunc();
        setModalOpen(false);
      }
    } catch (e) {
      antNotification.error('Ошибка!');
      setModalOpen(false);
    }
  };

  const ReportAlertComponent = useMemo(() => {
    if (reportStatus === 'submitted' && !loading) {
      return (
        onlySuccessTask
          ? (
            <Alert
          // style={{ paddingInline: '16px', paddingBlock: '16px' }}
              message={(
                <>
                  <div>
                    Отчет по рабочим дням за прошлый месяц (
                    {capitalize(dateToFormat4(lastMonthDate))}
                    ) отправлен!
                  </div>
                  <Button
                    className="mt-3"
                    type="primary"
                    style={{ border: '#faad14', background: '#52c41a' }}
                    onClick={() => setModalOpen(true)}
                  >
                    Скорректировать отчет за прошлый месяц (
                    {capitalize(dateToFormat4(lastMonthDate))}
                    )
                  </Button>
                </>
          )}
              type="success"
              showIcon
              description={(<div />)}
            />
          ) : null
      );
    }

    if (!loading) {
      return (
        onlySuccessTask ? null : (
          <Alert
            message={(
              <>
                <div>
                  Вы не отправили отчет за прошлый месяц (
                  {capitalize(dateToFormat4(lastMonthDate))}
                  )!
                  Пожалуйста, отправьте отчет!
                </div>
                <Button
                  className="mt-3"
                  type="primary"
                  style={{ border: '#faad14', background: '#ffa500' }}
                  onClick={() => setModalOpen(true)}
                >
                  Отправить отчет за прошлый месяц (
                  {capitalize(dateToFormat4(lastMonthDate))}
                  )
                </Button>
              </>
          )}
            type="warning"
            showIcon
            description={(<div />)}
          />
        )
      );
    }

    if (loading) {
      return (
        <Spin
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          spinning
        />
      );
    }

    return null;
  }, [JSON.stringify(monthlyReport), loading]);

  useEffect(() => {
    if (actor && monthlyStorageUUID && actor === monthlyStorageInfo?.actor) {
      initFunc();
    }
  }, [actor, monthlyStorageUUID, isModalOpen]);

  return (
    <>
      {ReportAlertComponent}
      <UserStatisticsForm
        actor={actor}
        isModalOpen={isModalOpen}
        onCancelModal={() => setModalOpen(false)}
        currentMonth={lastMonthDate}
        onFinish={onFinish}
        formSettings={{
          title: `Отправка отчета за ${capitalize(dayjs(lastMonthDate).format('MMMM, YYYY'))}`,
          totalWorksLabel: capitalize('Отработанные часы: '),
          commentLabel: capitalize('Комментарий:'),
        }}
      />
    </>
  );
}

UserReportForLastMonth.propTypes = {

};
