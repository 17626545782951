import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'antd';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import ArtifactCard from './artifacts/ArtifactCard';
import useDepartmentsStorage from '../../../redux/departments/hooks/useDepartmentsStorage';
import { getAllArtifacts, getSingleArtifact } from '../../../redux/departments/actions/artifactsActions';
import { getProfileInfo } from '../../../redux/profile/selectors';
import { RESET_SINGLE_ARTIFACT } from '../../../redux/departments/slices/artifactsSlice';
import PageWrapper from '../../../components/PageWrapper';
import ArtifactCardForMyDep from './artifacts/ArtifactCardForMyDep';
import { getAllDepartments } from '../../../redux/departments/actions/departmentsActions';
import { getDeliveryPartition } from '../../../redux/config/selectors';

export default function MyDepartmentPage({}) {
  const dispatch = useDispatch();

  const myProfileInfo = useSelector(getProfileInfo);

  const partitionUUID = useSelector(getDeliveryPartition);

  const [myCards, setMyCards] = useState([]);
  const [departments, setDepartments] = useState([]);

  const initEmployeesFunc = async () => {
    const res = await dispatch(getAllArtifacts(partitionUUID, {
      entity_type: 'user',
      actor: myProfileInfo?.uuid,
      depth: 0,
    }, [
      'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
      'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
      'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
    ]));

    setMyCards(res?.data ?? []);
  };

  const initFunc = async () => {
    const res = await dispatch(getAllDepartments({}, partitionUUID, [
      'GET_DEPARTMENTS_FOR_SELECT_REQUEST',
      'GET_DEPARTMENTS_FOR_SELECT_SUCCESS',
      'GET_DEPARTMENTS_FOR_SELECT_FAILURE',
    ]));
    if (res) {
      setDepartments(res?.data);
    }
  };

  const getDepartmentForTitle = (uuid) => {
    const dep = departments?.find((el) => el?.uuid === uuid);
    return dep ? `(${dep?.params?.title})` : '(Нет отдела)';
  };

  useEffect(() => {
    if (partitionUUID && myProfileInfo?.uuid) {
      initEmployeesFunc();
    }
  }, [partitionUUID, myProfileInfo?.uuid]);

  useEffect(() => {
    if (partitionUUID) {
      initFunc();
    }
  }, [partitionUUID]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Мой отдел
        </Flex>
      )}
    >
      <Flex className="w-full">
        <Flex className="w-1/2" vertical>
          {myCards?.map((el) => (
            <Flex
              className="mb-6"
            >
              <ArtifactCardForMyDep
                title={`Сотрудник ${getDepartmentForTitle(el?.params?.department)}`}
                artifact={el}
                disabledFull
                artifactUUID={el?.uuid}
                onUpdateArtifactCallback={() => {}}
                onDeleteArtifactCallback={() => {}}
                onBackCallback={() => {}}
                onReloadListCallback={() => {}}
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </PageWrapper>
  );
}
