import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Flex, Input, Modal, Segmented,
  Upload,
  message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { func, object, string } from 'prop-types';
import { get, head } from 'lodash';
import cn from 'classnames';
import { createArtifact } from '../../../../redux/departments/actions/artifactsActions';
import EntityDepartmentForArtifact from '../common/EntityDepartmentForArtifact';
import AntUploaderFiles from '../../../../components/commonComponents/AntUploaderFiles';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import AssigningUserCard from '../users/AssigningUserCard';
import useAPI from '../../../../api/useAPI';

const { TextArea } = Input;

export default function AddArtifactButton({
  parent,
  showUserCreateButton,
  artifactsStorage,
  defaultDepartmentUUID = '',
  onCreateArtifactCallback,
}) {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedType, setSelectedType] = useState('instruction');
  const [department, setDepartment] = useState('');
  const [filesList, setFiles] = useState([]);
  const [user, setUser] = useState({});

  const [defaultDepartment, setDefaultDepartment] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);

  const {
    getDefaultDepartment,
    updateUserEntity,
    createEntityPermissions,
  } = useAPI();

  const options = [{
    label: 'Инструкция',
    value: 'instruction',
  }, {
    label: 'Файл',
    value: 'file',
  }, {
    label: 'Сотрудник',
    value: 'user',
    hidden: !showUserCreateButton,
  }].filter((el) => !el?.hidden);

  const onCreateArtifact = async () => {
    if (selectedType) {
      createEntityPermissions({
        data: {
          entity_uuid: department,
          data: [{
            actor: user?.actor,
            create: true,
            list: true,
            read: true,
            update: false,
            delete: false,
            set: false,
          }],
        },
      });
      onCloseModal();
      await updateUserEntity(user?.value, {
        parent: department,
      });

      if (onCreateArtifactCallback) {
        onCreateArtifactCallback(user?.value);
      }

      return;
    }
    const newArtifact = {
      entity_type: selectedType,
      parent: department || parent,
      params: {
        department,
        status: 'active',
        description,
        title,
      },
    };
    const newFormData = new FormData();

    newFormData.append('data', JSON.stringify(newArtifact));

    if (filesList?.length !== 0) {
      for (let i = 0; i < filesList?.length; i += 1) {
        newFormData.append('file', filesList?.[i]);
      }
    }
    onCloseModal();
    dispatch(createArtifact(newFormData))
      .then((res) => {
        if (onCreateArtifactCallback) {
          onCreateArtifactCallback(get(head(res), 'uuid', ''));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initFunc = async () => {
    const res = await getDefaultDepartment();
    setDefaultDepartment(res);
  };

  useEffect(() => {
    if (isOpen) {
      setDepartment(defaultDepartmentUUID);
    } else {
      setTitle('');
      setDescription('');
      setUser({});
      setSelectedType('instruction');
      setDepartment(null);
      setFiles([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (partitionUUID) {
      initFunc();
    }
  }, [partitionUUID]);

  return (
    <>
      <Button
        size="small"
        onClick={onOpenModal}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Создать артефакт
      </Button>
      <Modal
        open={isOpen}
        title="Создание артефакта"
        onCancel={onCloseModal}
        footer={null}
        centered
        width={600}
        destroyOnClose
      >
        <Flex align="center" justify="space-between" className="mb-3">
          <Flex align="center">
            <span className="mr-1">Тип</span>
            <Segmented
              options={options}
              value={selectedType}
              onChange={setSelectedType}
            />
          </Flex>
        </Flex>
        <Flex align="center" justify="space-between" className="mb-3">
          <EntityDepartmentForArtifact
            department={department}
            artifactsStorage={artifactsStorage}
            onEditCallback={({ department: dep }) => setDepartment(dep)}
          />
        </Flex>
        {(selectedType === 'instruction'
          || selectedType === 'file'
        ) && (
        <Flex
          vertical
          className="mb-3"
        >
          <span>
            Название
          </span>
          <Input
            value={title}
            maxLength={128}
            disabled={selectedType === 'employee'}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Flex>
        )}
        {selectedType === 'instruction' && (
        <Flex
          vertical
          className="mb-3"
        >
          <span>
            Инструкция
          </span>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            maxLength={8000}
            row={4}
          />
        </Flex>
        )}
        {selectedType === 'file' && (
        <AntUploaderFiles
          uploadProps={{ showUploadList: { showDownloadIcon: false } }}
          filesList={filesList}
          setFiles={setFiles}
        />
        )}
        {selectedType === 'user' && (
        <Flex
          vertical
        >
          <span
            className="text-base mb-2 -mt-2"
          >
            Пользователь:
          </span>
          <Flex
            className="w-full mt-2"
            justify="space-between"
          >
            <Flex
              className="w-1/2"
            >
              <AssigningUserCard
                title=""
                popoverChildTitle={(
                  <span
                    className="mr-2 text-stone-500"
                  >
                    Руководитель:
                  </span>
                )}
                parent={defaultDepartment?.uuid}
                selected={user}
                onUserSelect={setUser}
              />
            </Flex>
            <Flex
              vertical
              className="w-1/2 h-full"
            >
              <Alert
                type="info"
                className="-mt-1"
                message="Памятка"
                description={(
                  <Flex
                    vertical
                  >
                    <p className="!text-base">
                      - Для назначения пользователя в отдел необходимо выбрать его в списке.
                    </p>
                    <p className="!text-base">
                      - Для добавления доступны только пользователи,
                      которые не состоят в другом отделе.
                    </p>
                  </Flex>
              )}
              />
            </Flex>
          </Flex>
        </Flex>
        )}
        <Flex
          className="mt-3"
          justify="end"
        >
          <Button
            className="mr-2 !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white"
            onClick={onCloseModal}
          >
            Отмена
          </Button>
          <Button
            disabled={(selectedType === 'user' && (!user?.actor || !department))}
            className={cn('border-green-500 text-green-500 hover:bg-green-500 hover:!text-white', {
              'border !border-gray-500 text-gray-500 hover:!bg-gray-500 hover:!text-white': selectedType === 'user' && !user?.actor,
            })}
            onClick={onCreateArtifact}
          >
            Создать
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

AddArtifactButton.propTypes = {
  parent: string,
  artifactsStorage: string,
  defaultDepartmentUUID: string,
  onCreateArtifactCallback: func,
};
