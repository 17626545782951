import { mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Divider, Flex, Segmented, Upload,
} from 'antd';
import { func, object, string } from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import EntityTitleForCard from '../common/EntityTitleForCard';
import EntityStatusForCard from '../common/EntityStatusForCard';
import EntityDepartmentForArtifact from '../common/EntityDepartmentForArtifact';
import EntityDescriptionForCard from '../common/EntityDescriptionForCard';
import EntityFilesForCard from '../common/EntityFilesForCard';

export default function FileArtifactView({
  artifactsStorage,
  artifact,
  onUpdateArtifactCallback,
  onDeleteFileCallback,
}) {
  const dispatch = useDispatch();

  const {
    params: {
      department: artifactDepartment,
      status: defaultArtStatus,
    } = {},
  } = artifact || {};

  const changeArtStatus = (statusValue) => {
    onUpdateArtifactCallback?.({ status: statusValue });
  };

  const changeArtFiles = (files) => {
    onUpdateArtifactCallback?.(null, { file: files }, { useFormData: true });
  };

  return (
    <Flex
      vertical
    >
      <Flex>
        <EntityTitleForCard
          className="mx-12"
          title={get(artifact, ['params', 'title'], '')}
          onEditCallback={onUpdateArtifactCallback}
        />
      </Flex>
      <Divider className="my-2" />
      <Flex className="mx-12" justify="space-between" align="center">
        <EntityStatusForCard
          className="flex items-center flex-shrink-0"
          title="Статус"
          currentValue={defaultArtStatus}
          classNameForTitle="mr-2 wrapper-title"
          classNameForButtonsBox="p-1"
          withIcons
          withBorder
          onChangeValue={changeArtStatus}
        />
        <EntityDepartmentForArtifact
          department={artifactDepartment}
          artifactsStorage={artifactsStorage}
          onEditCallback={onUpdateArtifactCallback}
        />
      </Flex>
      <Divider className="my-2" />
      <EntityDescriptionForCard
        className="mx-12"
        description={get(artifact, ['params', 'description'], '')}
        onEditCallback={onUpdateArtifactCallback}
      />
      <Divider className="my-2" />
      <EntityFilesForCard
        className="mx-12"
        files={get(artifact, ['files'], [])}
        onDeleteFile={onDeleteFileCallback}
        onEditCallback={changeArtFiles}
      />
    </Flex>
  );
}

FileArtifactView.propTypes = {
  storageUUID: string,
  artifact: object,
  onUpdateArtifactCallback: func,
};
