import {
  mdiAlertCircleOutline,
  mdiArrowLeft, mdiChevronDown, mdiChevronRight, mdiChevronUp, mdiPencil, mdiTriangle,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Avatar,
  Button, Card, Divider, Dropdown, Flex,
  Modal,
  Space,
  Tabs,
  Tooltip,
} from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalize,
  get, head, isNil, map, split, upperFirst,
} from 'lodash';
import dayjs from 'dayjs';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { func, object, string } from 'prop-types';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import { deleteSingleDepartment, updateSingleDepartment } from '../../../redux/departments/actions/departmentsActions';
import EntityTitleForCard from './common/EntityTitleForCard';
import EntityDescriptionForCard from './common/EntityDescriptionForCard';
import CommonInfo from './common/CommonInfo';
import CopyURL from '../../../components/commonComponents/CopyURL';
import { getAllArtifacts } from '../../../redux/departments/actions/artifactsActions';
import EmployeesForDepartment from './department/EmployeesForDepartment';
import useAPI from '../../../api/useAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import VacationsForDepartment from './department/VacationsForDepartment';
import EmployeeNameForDepartment from './department/EmployeeNameForDepartment';
import PanelForDepartment from './department/PanelForDepartment';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { getListAndReadEntities } from '../../../api/actions/entity';
import AssigningUserPopover from './users/AssigningUserPopover';

const items = [
  {
    key: '1',
    label: 'Сотрудники',
  },
  {
    key: '2',
    label: 'Отпуска',
  },
  {
    key: '3',
    label: 'Дашборд',
  },
];

export default function DepartmentCard({
  department = {},
  departmentUUID = '',
  className = '',
  onBackDepartmentCallback,
  onEditDepartmentCallback,
  onDeleteDepartmentCallback,
}) {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [isGlobalEdit, setIsGlobalEdit] = useState(false);

  const { createEntityPermissions } = useAPI();

  const {
    uuid,
    params: {
      leader,
      deputy,
      leaderActorUUID,
      deputyActorUUID,
      title,
      description,
    } = {},
  } = department || {};

  const [newTitle, setNewTitle] = useState(title);
  const [newDesc, setNewDesc] = useState(description);
  const [newLeader, setNewLeader] = useState(leader);
  const [newDeputy, setNewDeputy] = useState(deputy);

  const [activeKey, setActiveKey] = useState('1');

  const [showFullCard, setShowFullCard] = useState(true);

  const onChangeLeader = (user) => {
    const newUUID = get(user, 'actor', '');

    if (newUUID === newLeader?.actor) {
      setNewLeader(null);
      return;
    }
    if (newUUID === newDeputy?.actor) {
      setNewDeputy(newLeader);
    }
    setNewLeader({
      actor: user?.actor,
      uuid: user?.value,
      first_name: user?.user?.uinfo?.first_name,
      last_name: user?.user?.uinfo?.last_name,
    });
  };

  const onChangeDeputy = (user) => {
    const newUUID = get(user, 'actor', '');

    if (newUUID === newDeputy?.actor) {
      setNewDeputy(null);
      return;
    }
    if (newUUID === newLeader?.actor) {
      setNewLeader(newDeputy);
    }
    setNewDeputy({
      actor: user?.actor,
      uuid: user?.value,
      first_name: user?.user?.uinfo?.first_name,
      last_name: user?.user?.uinfo?.last_name,
    });
  };

  const onUpdateDepartment = async (data) => {
    const res = await dispatch(updateSingleDepartment({
      parent: partitionUUID,
      entity_uuid: departmentUUID,
      params: {
        ...data,
      },
    }));
    if (onEditDepartmentCallback) {
      onEditDepartmentCallback(head(res));
    }
  };

  const onDeleteDepartment = async () => {
    Modal.warning({
      title: `${get(department, ['params', 'title'])}`,
      content: 'Вы уверены, что хотите удалить отдел?',
      width: 'auto',
      centered: true,
      keyboard: true,
      maskClosable: true,
      closable: true,
      okText: 'Да',
      onOk: async () => {
        await dispatch(deleteSingleDepartment({
          parent: partitionUUID,
          entity_uuid: departmentUUID,
        }));

        onDeleteDepartmentCallback();
      },
    });
  };

  const onSave = () => {
    onUpdateDepartment({
      title: newTitle,
      description: newDesc,
      leader: newLeader,
      deputy: newDeputy,
      leaderActorUUID: newLeader?.actor,
      deputyActorUUID: newDeputy?.actor,
    });
    if (newLeader?.actor !== leaderActorUUID) {
      const data = [];
      if (newLeader?.actor) {
        data.push({
          actor: newLeader?.actor,
          create: true,
          list: true,
          read: true,
          update: true,
          delete: true,
          set: true,
        });
      }
      if (leaderActorUUID) {
        data.push({
          actor: leaderActorUUID,
          create: false,
          list: false,
          read: false,
          update: false,
          delete: false,
          set: false,
        });
      }
      createEntityPermissions({
        data: {
          entity_uuid: departmentUUID,
          data,
        },
      });
    }
    if (newDeputy?.actor !== deputyActorUUID) {
      const data = [];
      if (newDeputy?.actor) {
        data.push({
          actor: newDeputy?.actor,
          create: true,
          list: true,
          read: true,
          update: true,
          delete: true,
          set: false,
        });
      }
      if (deputyActorUUID) {
        data.push({
          actor: deputyActorUUID,
          create: false,
          list: false,
          read: false,
          update: false,
          delete: false,
          set: false,
        });
      }
      createEntityPermissions({
        data: {
          entity_uuid: departmentUUID,
          data,
        },
      });
    }
    setIsGlobalEdit(false);
  };

  const onOpenGlobalEdit = () => {
    setNewTitle(title);
    setNewDesc(description);
    setNewLeader(leader);
    setNewDeputy(deputy);
    setIsGlobalEdit(true);
  };

  useEffect(() => {
    setNewTitle(title);
    setNewDesc(description);
    setNewLeader(leader);
    setNewDeputy(deputy);
  }, [JSON.stringify(department)]);

  return (
    <>
      <Card
        title={(
          <Flex
            className="w-full"
            justify="space-between"
          >
            <Flex
              align="center"
            >
              <Flex
                align="center"
                className="cursor-pointer"
                onClick={onBackDepartmentCallback}
              >
                <Icon path={mdiArrowLeft} size={0.9} />
                <span className="ml-3 text-xl">
                  Отдел
                  {' '}
                  {!showFullCard && <span>{title?.length > 25 ? `${title?.slice(0, 25)}...` : title}</span>}
                </span>
              </Flex>
              <CommonInfo
                iconSize={1}
                style={{
                  width: 25,
                  minWidth: 25,
                  height: 25,
                }}
                className="ml-2 mt-1"
                creator={`${capitalize(get(department, 'uinfo.first_name', ''))} ${get(department, 'uinfo.last_name', '')}`}
                createdDate={get(department, 'created', '')}
              />
              <CopyURL
                entityType="department"
                entityUUID={uuid}
                className="ml-1 pt-2.5 mt-0.5 pl-2"
                iconSize={1}
                style={{
                  width: 25,
                  minWidth: 25,
                  height: 25,
                }}
              />
            </Flex>
            <Flex>
              {isGlobalEdit ? (
                <>
                  <Button
                    size="small"
                    onClick={() => setIsGlobalEdit(false)}
                    className="mr-2 !text-orange-500 border !border-orange-500 hover:bg-orange-100"
                  >
                    Отмена
                  </Button>
                  <Button
                    size="small"
                    onClick={onSave}
                    className="mr-2 !text-green-500 border !border-green-500 hover:bg-green-100"
                  >
                    Сохранить
                  </Button>
                </>
              ) : (
                <Button
                  size="small"
                  onClick={onOpenGlobalEdit}
                  className="mr-2 !text-orange-500 border !border-orange-500 hover:bg-orange-100"
                >
                  Редактировать
                </Button>
              )}
              <Button
                size="small"
                onClick={onDeleteDepartment}
                className="!text-red-500 border !border-red-500 hover:bg-red-100"
              >
                Удалить
              </Button>
            </Flex>
          </Flex>
      )}
        className={cn(`w-full h-max p-0 ${className}`, {
          'blue-base-card': true,
        })}
      >
        {showFullCard
        && (
        <Flex
          vertical
          className="py-2"
        >
          <EntityTitleForCard
            className="mx-12"
            hideEditButton
            isGlobalEdit={isGlobalEdit}
            onChangeValue={setNewTitle}
            title={title}
            onEditCallback={onUpdateDepartment}
          />
          <Divider className="my-2" />
          <Flex
            justify="space-between"
            className="mx-12 text-sm font-normal"
          >
            <Flex
              align="center"
            >
              <span
                className="mr-2"
              >
                Руководитель:
              </span>
              {isGlobalEdit && (
              <AssigningUserPopover
                title="Руководитель"
                popoverChildTitle={(
                  <span />
            )}
                parent={partitionUUID}
                selected={newLeader}
                onUserSelect={onChangeLeader}
              />
              )}
              {!isGlobalEdit && leader && (
              <span className="text-blue-500">
                {get(leader, 'first_name', '')}
                {' '}
                {get(leader, 'last_name', '')}
              </span>
              )}
              {!isGlobalEdit && !leader && (
              <span
                className="text-gray-500"
              >
                Нет руководителя
              </span>
              )}
            </Flex>
            <Flex
              align="center"
            >
              <span
                className="mr-2"
              >
                Заместитель:
              </span>
              {isGlobalEdit && (
              <AssigningUserPopover
                title="Заместитель"
                popoverChildTitle={(
                  <span />
            )}
                parent={partitionUUID}
                selected={newDeputy}
                onUserSelect={onChangeDeputy}
              />
              )}
              {!isGlobalEdit && deputy && (
              <span className="text-blue-500">
                {get(deputy, 'first_name', '')}
                {' '}
                {get(deputy, 'last_name', '')}
              </span>
              )}
              {!isGlobalEdit && !deputy && (
              <span
                className="text-gray-500"
              >
                Нет заместителя
              </span>
              )}
            </Flex>
          </Flex>
          <Divider className="my-2" />
          <EntityDescriptionForCard
            hideEdit
            onChangeValue={setNewDesc}
            isGlobalEdit={isGlobalEdit}
            className="mx-12"
            description={description}
            onEditCallback={onUpdateDepartment}
          />
          <Divider className="my-2" />
          <Flex
            vertical
            className="mx-6 -mt-2"
          >
            <Tabs
            // type="card"
              defaultActiveKey="1"
              activeKey={activeKey}
              items={items}
              onChange={setActiveKey}
              tabBarStyle={{ marginBottom: 0 }}
            />
            {activeKey === '1' && (
            <EmployeesForDepartment
              departmentUUID={departmentUUID}
              leader={leaderActorUUID}
              deputy={deputyActorUUID}
            />
            )}
            {activeKey === '2' && (
            <VacationsForDepartment
              departmentUUID={departmentUUID}
            />
            )}
            {activeKey === '3' && (
            <PanelForDepartment
              departmentUUID={departmentUUID}
            />
            )}
          </Flex>
        </Flex>
        )}
      </Card>

      <Flex
        className="w-full py-1 z-20 cursor-pointer text-blue-500 bg-sky-100"
        justify="center"
        align="center"
        onClick={() => setShowFullCard((prev) => !prev)}
      >
        {showFullCard
          ? (
            <Flex
              align="center"
            >
              <Icon path={mdiChevronUp} size={1.2} />
              <span>
                Свернуть
              </span>
            </Flex>
          ) : (
            <Flex
              align="center"
            >
              <Icon path={mdiChevronDown} size={1.2} />
              <span>
                Развернуть
              </span>
            </Flex>
          )}
      </Flex>
    </>
  );
}

DepartmentCard.propTypes = {
  parent: string,
  department: object,
  className: string,
  departmentUUID: string,
  onBackDepartmentCallback: func,
  onEditDepartmentCallback: func,
  onDeleteDepartmentCallback: func,
};
