import React, { useState } from 'react';

import {
  Card, Flex,
  Tooltip,
} from 'antd';

import { get, isNil } from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';
import {
  array, func, object, string,
} from 'prop-types';

import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import useURLParams from '../../../hooks/useURLParams';

import AddDepartmentButton from './AddDepartmentButton';
import BaseTableWithPagination from './common/BaseTableWithPagination/BaseTableWithPagination';

export default function DepartmentsList({
  selectedData,
  departments = [],
  paginationOptions = {},
  onReloadDepartments,
}) {
  const { getURLParams, addAndRemoveSearchParams } = useURLParams();

  const { department } = getURLParams();

  const [hovered, setHovered] = useState(false);
  const [showFullCard, setShowFullCard] = useState(false);

  const gerLabel = (user) => `${get(user, ['first_name'], '')} ${get(user, ['last_name'], '')}`;

  const columns = [
    {
      key: 'title',
      title: 'Название',
      dataIndex: ['params', 'title'],
      width: '40%',
      render: (cell) => (
        <span
          style={{
            wordBreak: 'break-word',
          }}
        >
          {cell}
        </span>
      ),
    },
    {
      key: 'leader',
      title: 'Руководитель',
      dataIndex: ['params', 'leader'],
      width: '25%',
      render: (cell, row) => {
        const label = gerLabel(cell);

        return (
          <Flex className="w-full">
            <Tooltip
              title={label}
            >
              {label?.length < 25 ? label : `${label?.slice(0, 25)}...`}
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      key: 'deputy',
      title: 'Заместитель',
      dataIndex: ['params', 'deputy'],
      width: '25%',
      render: (cell, row) => {
        const label = gerLabel(cell);

        return (
          <Flex className="w-full">
            <Tooltip
              title={label}
            >
              {label?.length < 25 ? label : `${label?.slice(0, 25)}...`}
            </Tooltip>
          </Flex>
        );
      },
    }, {
      key: 'created',
      title: 'Дата',
      dataIndex: ['created'],
      width: '10%',
      render: (cell) => dayjs(cell).format('YYYY-MM-DD'),
    }];

  const onRow = (record) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      if (uuid !== department) {
        addAndRemoveSearchParams({ department: uuid }, ['department']);
      } else {
        addAndRemoveSearchParams({}, ['department']);
      }
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === department,
  });

  return (
    <>
      <Card
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="w-full h-max p-0 pb-2 blue-base-card"
        title={(
          <Flex
            align="center"
          >
            <Flex
              className="text-xl"
            >
              Отделы
            </Flex>
            <AddDepartmentButton
              onReloadDepartments={onReloadDepartments}
            />
          </Flex>
      )}
      >
        <Flex
          className="mx-2"
          vertical
        >
          <BaseTableWithPagination
            columns={columns}
            data={department && !hovered && !showFullCard ? [selectedData] : departments}
            rowKey="uuid"
            size="small"
            className="transition-transform duration-300 ease-in-out"
            headerRowClassName="universal_header_table_row color-gray"
            onRow={onRow}
            rowClassName={rowClassName}
            hideListSizeOption={false}
            useCustomPagination
            pageSizeOptions={[10, 25, 50]}
            disablePagination={department && !hovered && !showFullCard}
            {...paginationOptions}
          />
        </Flex>
      </Card>
      {department && (
      <Flex
        className="w-full py-1 z-20 cursor-pointer text-blue-500 bg-sky-100"
        justify="center"
        align="center"
        onClick={() => setShowFullCard((prev) => !prev)}
      >
        {showFullCard
          ? (
            <Flex
              align="center"
            >
              <Icon path={mdiChevronUp} size={1.2} />
              <span>
                Свернуть
              </span>
            </Flex>
          ) : (
            <Flex
              align="center"
            >
              <Icon path={mdiChevronDown} size={1.2} />
              <span>
                Развернуть
              </span>
            </Flex>
          )}
      </Flex>
      )}
    </>
  );
}

DepartmentsList.propTypes = {
  selectedData: object,
  departments: array,
  paginationOptions: object,
  onReloadDepartments: func,
};
