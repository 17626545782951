import React from 'react';

import {
  Card, Flex,
  Tooltip,
} from 'antd';

import cn from 'classnames';
import { get } from 'lodash';
import dayjs from 'dayjs';
import {
  array, func, object, string,
} from 'prop-types';

import Icon from '@mdi/react';
import { mdiAlertCircleOutline, mdiCalendarClock, mdiCheckboxMarkedCircleOutline } from '@mdi/js';

import { useSelector } from 'react-redux';
import { artifactStatusFilterOptions, artifactTypeFilterOptions } from '../../../../redux/departments/hooks/filterHelper';

import AddArtifactButton from './AddArtifactButton';
import BaseTableWithPagination from '../common/BaseTableWithPagination/BaseTableWithPagination';
import useURLParams from '../../../../hooks/useURLParams';
import EmployeeLabel from '../common/EmployeeLabel';
import { getArtifactsStorageUUID } from '../../../../redux/departments/selectors/artifactsStorageSelector';

export default function ArtifactsList({
  artifacts = [],
  showUserCreateButton,
  department = {},
  departmentUUID = '',
  parent,
  paginationOptions,
  title = 'Артефакты',
  cardClassName = '',
  onCreateArtifactCallback,
}) {
  const {
    artifactFilterConfig = {},
  } = paginationOptions;

  const { setSearchParams, getURLParams } = useURLParams();

  const { activeArtifact } = getURLParams();

  const artifactsStorageUUID = useSelector(getArtifactsStorageUUID);

  const {
    params: {
      leader = '',
      deputy = '',
    } = {},
  } = department || {};

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeArtifact,
  });

  const columns = [
    {
      key: 'type',
      title: 'Тип',
      dataIndex: ['entity_type'],
      width: '10%',
      filterMode: 'tree',
      filterSearch: false,
      filters: artifactTypeFilterOptions,
      defaultFilteredValue: artifactFilterConfig?.type,
      render: (cell) => {
        switch (cell) {
          case 'file': {
            return <Flex className="text-blue-500">Файл</Flex>;
          }
          case 'instruction': {
            return <Flex className="text-blue-500">Инструкция</Flex>;
          }
          case 'user': {
            return <Flex className="text-green-500">Сотрудник</Flex>;
          }
          default: return '';
        }
      },
    },
    {
      key: 'title',
      title: 'Название',
      dataIndex: ['params', 'title'],
      width: '50%',
      render: (cell, row) => (row?.entity_type === 'user'
        ? (
          <EmployeeLabel
            user={row}
            leader={leader}
            deputy={deputy}
          />
        ) : (
          <span
            style={{
              wordBreak: 'break-word',
            }}
          >
            {cell}
          </span>
        )),
    },
    {
      key: 'status',
      title: 'Статус',
      // filterMode: 'tree',
      // filterSearch: false,
      // filters: artifactStatusFilterOptions,
      // defaultFilteredValue: artifactFilterConfig?.status,
      dataIndex: ['params', 'status'],
      width: '20%',
      render: (cell) => {
        switch (cell) {
          case 'active': {
            return (
              <Flex
                align="center"
                style={{
                  width: '75px',
                  border: '1px solid #22c55e',
                }}
                className="w-max border-green-500 bg-green-50 px-2 rounded text-sm text-green-500"
              >
                <Flex
                  className="mr-1"
                >
                  <Icon path={mdiCheckboxMarkedCircleOutline} size={0.7} />
                </Flex>
                Active
              </Flex>
            );
          }
          case 'archive': {
            return (
              <Flex
                align="center"
                style={{
                  width: '75px',
                  border: '1px solid #A9A9A9',
                }}
                className="w-max border-gray-500 bg-gray-50 px-2 rounded text-sm text-gray-500"
              >
                <Flex
                  className="mr-1"
                >
                  <Icon path={mdiCalendarClock} size={0.7} />
                </Flex>
                Archive
              </Flex>
            );
          }
          default:
            return (
              <Flex
                align="center"
                style={{
                  width: '75px',
                  border: '1px solid #22c55e',
                }}
                className="w-max border-green-500 bg-green-50 px-2 rounded text-sm text-green-500"
              >
                <Flex
                  className="mr-1"
                >
                  <Icon
                    path={mdiCheckboxMarkedCircleOutline}
                    size={0.7}
                  />
                </Flex>
                Active
              </Flex>
            );
        }
      },
    }, {
      key: 'created',
      title: 'Дата',
      dataIndex: ['created'],
      width: '20%',
      render: (cell) => dayjs(cell).format('YYYY-MM-DD'),
    }];

  const onRow = (record) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      setSearchParams({ artifact: uuid });
    },
  });

  return (
    <Card
      className={`w-full mt-4 p-0 pb-3 h-max blue-base-card ${cardClassName}`}
      title={(
        <Flex
          align="center"
        >
          <Flex
            className="text-xl"
          >
            {title}
          </Flex>
          <AddArtifactButton
            showUserCreateButton={showUserCreateButton}
            parent={parent}
            artifactsStorage={artifactsStorageUUID}
            defaultDepartmentUUID={departmentUUID}
            onCreateArtifactCallback={onCreateArtifactCallback}
          />
        </Flex>
)}
    >
      <Flex className="px-2 w-full" vertical>
        <BaseTableWithPagination
          columns={columns}
          data={artifacts}
          rowKey="uuid"
          size="small"
          className="w-full h-full !mb-3"
          onRow={onRow}
          rowClassName={rowClassName}
          useCustomPagination
          disablePagination={false}
          {...paginationOptions}
        />
      </Flex>
    </Card>
  );
}

ArtifactsList.propTypes = {
  artifacts: array,
  department: object,
  departmentUUID: string,
  parent: string,
  paginationOptions: object,
  title: string,
  cardClassName: string,
  onCreateArtifactCallback: func,
};
