import { head } from 'ramda';

export const APP_SERVICE_NAME = 'HRM54';
export const SERVICE_PARTITION_NAME = 'DELIVERY';

export const APP_LABEL = 'HRM54';

// Default DEV app config
export const APP_CONFIG = {
  app_domain: 'https://reports54.09releasetest.54origins.com',
  entity_domain: 'https://entity.09releasetest.54origins.com',
  auth_domain: 'https://auth.09releasetest.54origins.com',
};

export const hostSplit = head(window.location.host.split('.'));

export const entityURL = hostSplit.indexOf(APP_SERVICE_NAME) !== -1
  ? window.location.origin.replace(APP_LABEL, 'entity')
  : APP_CONFIG.entity_domain;

export const serviceURL = hostSplit.indexOf(APP_SERVICE_NAME) !== -1
  ? window.location.origin
  : APP_CONFIG.app_domain;

export const authURL = hostSplit.indexOf(APP_SERVICE_NAME) !== -1
  ? window.location.origin.replace(APP_LABEL, 'auth')
  : APP_CONFIG.auth_domain;
