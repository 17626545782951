import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  capitalize, get, head, isNil,
} from 'lodash';
import dayjs from 'dayjs';
import { Divider, Flex } from 'antd';
import { array } from 'prop-types';
import useAPI from '../../../../api/useAPI';

export default function PanelForDepartment({
  departmentUUID = '',
}) {
  const {
    getMonthlyReport,
  } = useAPI();

  const [data, setData] = useState([]);

  const previous1Month = dayjs().subtract(1, 'month').format('YYYY-MM');
  const previous2Month = dayjs().subtract(2, 'month').format('YYYY-MM');
  const previous3Month = dayjs().subtract(3, 'month').format('YYYY-MM');

  const initMonthlyReports = async () => {
    const res = await getMonthlyReport(departmentUUID, {
      depth: 0,
      params: {
        date: [previous1Month, previous2Month, previous3Month],
      },
    });

    const reports = res?.data ?? [];

    const newData = reports?.reduce((acc, report) => {
      const currMonth = acc?.find((el) => el?.month === report?.params?.date);
      if (currMonth) {
        currMonth.internalHours += +get(report, 'params.statistics.internalWork', 0);
        currMonth.clientHours += +get(report, 'params.statistics.clientWork', 0);
        currMonth.total += +get(report, 'params.statistics.totalWorks', 0);
      }
      return acc;
    }, [{
      month: previous1Month,
      total: 0,
      internalHours: 0,
      clientHours: 0,
    }, {
      month: previous2Month,
      total: 0,
      internalHours: 0,
      clientHours: 0,
    }, {
      month: previous3Month,
      total: 0,
      internalHours: 0,
      clientHours: 0,
    }]);

    setData(newData);
  };

  useEffect(() => {
    if (departmentUUID) {
      initMonthlyReports();
    }
  }, [departmentUUID]);

  return (
    <Flex
      justify="space-between"
      className="my-2"
    >
      {data?.map((el) => (
        <Flex
          vertical
        >
          <Flex
            className="text-lg"
          >
            Отчет за
            {' '}
            {capitalize(dayjs(el?.month).format('MMMM YYYY'))}
          </Flex>
          <Flex
            justify="space-between"
            style={{
              width: 200,
            }}
            className="ml-2 text-base"
          >
            <span>
              Клиентские часы
            </span>
            <span>
              {el?.clientHours}
            </span>
          </Flex>
          <Flex
            style={{
              width: 200,
            }}
            justify="space-between"
            className="ml-2 text-base"
          >
            <span>
              Внутренние часы
            </span>
            <span>
              {el?.internalHours}
            </span>
          </Flex>
          <Divider
            className="my-2"
            plain
            style={{
              width: 200,
            }}
          />
          <Flex
            style={{
              width: 200,
            }}
            justify="space-between"
            className="ml-2 text-base"
          >
            <span>
              Всего
            </span>
            <span>
              {+el?.total}
            </span>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

PanelForDepartment.propTypes = {
  employees: array,
};
